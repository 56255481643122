// const specialCharsOne = /[&\/\\#,+()$~%.'":*?<>{}]/g;
// const specialCharsTwo = /[^a-zA-Z0-9]/g;
// text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').replace(/ /g,'-');
export const splitText = (text) => {
  return text.split("");
};

export const contactText = (text) => {
  let splitedText = text.replace(" ", "-").split(" ");

  const newText = splitedText.map((item) => {
    if (item.includes("/") || item.includes("&")) {
      return item.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "");
    } else {
      return item;
    }
  });

  return newText.join("").toLowerCase();
};

export const addRemoveElementClassOnHover = (className, action, id) => {
  const items = document.querySelectorAll(`.${className}`);
  Array.from(items).filter((item, index) => {
    if (index + 1 !== id) {
      action === "add"
        ? item.classList.add(`${className}--inactive`)
        : item.classList.remove(`${className}--inactive`);
    }
    return null;
  });
};



export function debounce(func, wait, immediate) {
  var timeout

  return function executedFunction() {
    var context = this
    var args = arguments

    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
