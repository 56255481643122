import React from "react"
import Img from "gatsby-image"
import urlSlug from "url-slug"
import { Link } from "gatsby"

function Post({node,index}) {
  return (
    <li className={`md-posts__item md-posts__item--${index}`}>
      <Link className="md-posts__link" to={`/${node.slug}/${urlSlug(node.title)}`}>
        <div className="md-posts__img-wrapper">
          <Img
            className="md-posts__img"
            fluid={node.image.fluid}
            alt={node.title}
          />
        </div>
        <div className="md-posts__details">
          {/* <span className="md-posts__type">posts - soup</span> */}
          <h2 className="md-posts__title">{node.title}</h2>
          <span className="md-posts__time-servings">
            {node.cooktime} min - {node.servings} portions
          </span>
        </div>
      </Link>
    </li>
  )
}

export default Post
