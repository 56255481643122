import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// HERO ANIMATIONS

export function heroAnimation(hero,bgLeft,bgRight,content,title) {
  ScrollTrigger.refresh();
  gsap.from(hero.current,{
    duration:0.1,
    autoAlpha:0,
    ease: "power3.out"
  })

  gsap.from(bgLeft.current,{
    duration:1,
    delay:0.1,
    left:"-100%",
    ease: "power3.in"
  })

  gsap.from(bgRight.current,{
    duration:1,
    delay:.5,
    autoAlpha:0,
    ease: "power3.in",
    onComplete: () => navAnimation(),
  })

  gsap.from(`.md-hero__title .md-hero__line-text`,{
    duration:1,
    delay:1,
    y:'50px',
    stagger: 0.3,
    autoAlpha:0,
    ease: "power3.out"
  })

  gsap.from(`.md-hero__btn-wrapper .md-link`,{
    duration:1,
    delay:1,
    y:"200px",
    stagger: 0.2,
    autoAlpha:0,
    ease: "power3.out"
  })
}


// NAV ANIMATION

export function navAnimation() {
  gsap.fromTo(".md-menu", {
    y:"-100px",
    autoAlpha:0,
    display: 'none',
    ease: "power3.inOut"
  },{
    duration:.8,
    delay:.3,
    y:"0px",
    autoAlpha:1,
    display: 'block',
    ease: "power3.out"
  })
}

// POSTS LIST ANIMATION

export function postsListMobileAnimation(type,index,pathname) {
  gsap.fromTo(
    pathname === '/' ? `.md-block--${type} .md-posts__item--${index}` : `.md-posts-list .md-posts__item--${index}`,
    {
      autoAlpha:0,
      y: 80,
    },
    {
      duration: 1,
      autoAlpha:1,
      y: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: pathname === '/' ? `.md-block--${type} .md-posts__item--${index}` : `.md-posts-list .md-posts__item--${index}`,
        start: "top center+=500",
        toggleActions: "play none none reverse",
        markers: false,
      },
    }
  );
}