import { useState, useEffect } from "react"
import { debounce } from "../utils/utils"

export default function useWindowSize() {
  /*
    Auto updating window dimensions on resize
    See Kevin's notes for what each does below
  */

  // define dimension variables for use in hook
  let height
  let width

  // Check for the window element.
  // Needed for server size rendering of Gatsby else use of window.innerHeight/Width will cause build failures.
  if (typeof window !== "undefined") {
    height = window.innerHeight
    width = window.innerWidth
  }

  // See default state of dimensions
  const [dimensions, setDimensions] = useState({
    windowHeight: height,
    windowWidth: width,
  })

  // Use hook to run the following code after page is loaded
  useEffect(() => {
    /*
      Debounce function for performance to check window resizing every 1000ms instead of of every ms. 
      Upon resize, set new dimension to state.
      Update 1000 value as needed. Note: 1000 = 1 second
    */

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
    }, 1000)

    // Listen for window resize and run the debounce resize function above.
    window.addEventListener(`resize`, debouncedHandleResize)
    // Remove the event listener if resizing stopped.
    return () => window.removeEventListener(`resize`, debouncedHandleResize)
  }, [])

  return dimensions
}
