import React from "react"
import { graphql } from 'gatsby'
import PostsList from "../components/postsList/PostsList"

function RecipesList(props) {
  return <PostsList type="recipes" props={props} />
}

export default RecipesList

export const recipesPageQuery = graphql`
  query recipesPageQuery($skip: Int!, $limit: Int!) {
    allContentfulRecipes(limit: $limit, skip: $skip,sort: {order: DESC, fields: publishDate}) {
      edges {
        node {
          title
          slug
          contentful_id
          cooktime
          createdAt
          preptime
          publishDate(formatString: "MMMM Do, YYYY")
          servings
          spaceId
          updatedAt
          tags
          ingredients {
            json
          }
          instructions {
            json
          }
          description {
            json
          }
          image {
            fluid {
              tracedSVG
              srcWebp
              srcSetWebp
              src
              sizes
              base64
              aspectRatio
              srcSet
            }
          }
        }
      }
    }
  }
`
