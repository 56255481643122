import React from "react"
import Layout from "../../layouts/layout/Layout"
import Pattern from "../../images/svg/patter-2-stroke.svg"
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

function CustomPage({ children, title, date }) {
  const { pathname } = useLocation();

  return (
    <Layout>
      <div className="md-page" name="mdhero">
        <div className="md-page__hero">
          <Pattern className="md-page__pattern" />
          <h1 className="md-page__title">{title}</h1>
          {date && <p className="md-page__date">{date}</p>}
          {pathname === "/404/" && (
            <Link className="md-link" to="/">Go to homepage</Link>
          )}
        </div>
        {children}
      </div>
    </Layout>
  )
}

export default CustomPage
