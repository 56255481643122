import React, { useEffect, useRef } from "react"
import { postsListAnimation } from "../../animations/desktop";
import { postsListMobileAnimation } from "../../animations/mobile";
import useWindowSize from "../../hooks/useWindowSize";
import Post from "../post/Post"
import {useLocation} from '@reach/router'

function Posts({ type, posts, postsNumber }) {
  const { pathname } = useLocation();
  const { windowWidth } = useWindowSize();
  const postsList = useRef(null);

  useEffect(() => {
    const itemList = Array.from(postsList.current.children);
    itemList.forEach((item,index) => {
      if(windowWidth > 1024) {
        postsListAnimation(type,index,pathname)
      } else {
        postsListMobileAnimation(type,index,pathname)
      }
    })
  },[pathname, type, windowWidth])
  
  return (
    <div className="md-posts">
      <div className="md-posts__content">
        <ul className="md-posts__list" ref={postsList}>
          {postsNumber
            ? posts
                .slice(0, 3)
                .map(({ node }, index) => (
                  <Post node={node} type={type} key={index} index={index}/>
                ))
            : posts.map(({ node }, index) => (
                <Post node={node} type={type} key={index} index={index}/>
              ))}
        </ul>
      </div>
    </div>
  )
}

export default Posts
