import { Link } from "gatsby"
import React from "react"
import CustomPage from "../customPage/CustomPage"
import Posts from "../posts/Posts"
import Seo from "../seo/Seo"
import { graphql, useStaticQuery } from "gatsby"

function PostsList({ props, type }) {
  const { site } = useStaticQuery(query)
  const {
    description,
    image,
  } = site.siteMetadata
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? `/${type}`
      : `/${type}/${(currentPage - 1).toString()}`
  const nextPage = `/${type}/${(currentPage + 1).toString()}`
  const posts =
    type === "recipes"
      ? props.data.allContentfulRecipes.edges
      : props.data.allContentfulBlog.edges
  const tagPosts =
    type === "tags"
      ? props.data.allContentfulRecipes.edges.concat(
          props.data.allContentfulBlog.edges
        )
      : null
  return (
    <>
      <Seo
        title={type.charAt(0).toUpperCase() + type.slice(1,type.length)}
        description={description || "nothin’"}
        image={image}
        article
      />
      <CustomPage title={type === "tags" ? props.pageContext.tag : type}>
        <div className={`md-posts-list md-posts-list--${type}`}>
          <div className="md-posts-list__container">
            <Posts type={type} posts={type === "tags" ? tagPosts : posts} />
            {type !== "tags" && (
              <div className="md-posts-list__links">
                {!isFirst && (
                  <Link className="md-link" to={prevPage}>
                    {" "}
                    previous page
                  </Link>
                )}
                {!isLast && (
                  <Link className="md-link" to={nextPage}>
                    {" "}
                    next page
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </CustomPage>
    </>
  )
}

export default PostsList

const query = graphql`
  query detailPagesSeo {
    site {
      siteMetadata {
        title
        titleTemplate
        author
        description
        url
        image
        twitterUsername
      }
    }
  }
`
